
export const InputWithDescription: React.FC<{
    label: string;
    value: string;
    onChange: (value: string) => void;
    description: string;
    hasError: boolean;
}> = ({ label, value, onChange, description, hasError }) => {
    return (
        <div className="w-full" onClick={(e) => e.stopPropagation()}>
            {!value && (
                <p className="text-sm text-gray-500 mb-1">{description}</p>
            )}
            <input
                type="text"
                className={`mt-2 w-full rounded-lg border ${hasError ? 'border-red-500' : 'border-gray-300'} py-2 px-3 text-sm text-gray-800 focus:border-blue-500 focus:ring-blue-500 focus:outline-none`}
                placeholder={label}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
            />
        </div>
    );
};