import { KeyWord } from "@/types/types";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

export const Chip = ({ item, color, onChange, className }: {
    item: KeyWord,
    color: string,
    onChange?: (value: string) => void,
    className?: string,
  }) => {
    return (
      <div className={`rounded-full px-3 py-1 ${color} text-gray-800 text-sm font-medium flex items-center gap-2 ${className}`} onClick={() => onChange && onChange(item.name)}  >
        {item.name}
      </div>
    );
  }


export const EditableChip = ({ item, color, onChange, checked, isEditing, onConfirm, onCancel }: {
  item: KeyWord,
  color: string,
  onChange?: (value: string) => void,
  checked: boolean,
  isEditing?: boolean,
  onConfirm?: (value: string) => void,
  onCancel?: () => void,
}) => {
  const [value, setValue] = useState(item.name);

  if (isEditing) {
    return (
      <div className={`rounded-full px-4 py-1 ${color} text-gray-800 text-sm font-medium flex items-center gap-2`}>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="bg-transparent outline-none"
          autoFocus
        />
        <>
          <button className="hover:text-green-500" onClick={() => onConfirm && onConfirm(value)}>
            <CheckCircleIcon className="h-5 w-5" />
          </button>
          <button className="hover:text-red-500" onClick={onCancel}>
            <XCircleIcon className="h-5 w-5" />
          </button>
        </>
      </div>
    );
  }

  return <Chip item={item} color={color} onChange={onChange} className={checked ? 'cursor-pointer' : 'opacity-50 cursor-pointer'} />;
}