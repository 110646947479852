import React from 'react';
import {
  Typography,
  Grid,
  TextField,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TitleInputProps {
  name: string;
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
}

export const TitleInput: React.FC<TitleInputProps> = ({ name, title, onChange, hasError }) => {
  const isMobile = window.innerWidth <= 600;
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {onChange ? (
          <Typography
            component="div"
            sx={{
              width: '100%',
            }}
          >
            <TextField
              name={name}
              value={title}
              onChange={onChange}
              error={hasError}
              placeholder={t('activityForm.exerciseName')}
              InputProps={{
                style: {
                  fontSize: isMobile ? '1rem' : '2rem',
                },
              }}
              fullWidth
              className={`border-b-2 focus:outline-none focus:border-blue-500 ${hasError ? 'border-red-500' : 'border-gray-200'
                }`}
            />
          </Typography>
        ) : (
          <Typography
            component="div"
            sx={{
              width: '100%',
              fontSize: isMobile ? '1rem' : '2rem',
              padding: '10px 0',
              borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            }}
          >
            {title}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};