import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_REST_URL } from '@/main';

interface AuthContextProps {
    isLoggedIn: boolean;
    signIn: (email: string, password: string) => Promise<void>;
    signOut: () => void;
    signUp: (username: string, email: string, password: string) => Promise<void>;
    userData: UserInfoProps;
    error: string | null;
    signInError: string | null;
    passwordMismatch: boolean;
    defaultLanguage: string;
    setPasswordMismatch: React.Dispatch<React.SetStateAction<boolean>>;
    currentUser: UserDataProps | null;
}
interface UserInfoProps {
    user_name: string;
    email: string;
    password: null;
}
interface UserDataProps {
    id: number;
    user_name: string;
    email: string;
}

const AuthContext = React.createContext<AuthContextProps | undefined>(
    undefined,
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    // State management
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [signInError, setSignInError] = React.useState<string | null>(null);
    const [passwordMismatch, setPasswordMismatch] = React.useState(false);
    const [defaultLanguage] = React.useState<string>("nb");
    const [userData, setUserData] = React.useState<UserInfoProps>({
        user_name: "",
        email: "",
        password: null,
    });
    const [currentUser, setCurrentUser] = React.useState<UserDataProps | null>(null);

    const navigate = useNavigate();

    // Helper functions
    const clearErrors = () => {
        setError(null);
        setSignInError(null);
    };

    const handleAuthResponse = async (response: Response, errorMessage: string) => {
        if (!response.ok) {
            throw new Error(errorMessage);
        }
        return await response.json();
    };

    // Fetch current user data
    const getCurrentUser = async () => {
        try {
            const url = `${BACKEND_REST_URL}/api/auth/current`;
            const token = sessionStorage.getItem("access_token");
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            const data = await handleAuthResponse(response, "Failed to fetch user data");
            setUserData(data);
            setCurrentUser(data);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setUserData({ user_name: "", email: "", password: null });
            handleSignOut();
        }
    };
    // Fetch /api/languages and store

    // Effects
    React.useEffect(() => {
        if (isLoggedIn) {
            getCurrentUser();
        }
    }, [isLoggedIn]);

    React.useEffect(() => {
        const accessToken = sessionStorage.getItem("access_token");
        setIsLoggedIn(!!accessToken);
    }, []);

    // Authentication handlers
    const handleSignIn = async (email: string, password: string) => {

        const url = `${BACKEND_REST_URL}/api/token`;
        const body = new URLSearchParams({
            username: email,
            password: password,
            scope: "",
            grant_type: "password",
        });

        
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: body,
            });
                
            if (response.ok) {
                const data = await handleAuthResponse(response, "Feil brukernavn eller passord");
                sessionStorage.setItem("access_token", data.access_token);
            setIsLoggedIn(true);
            navigate(-1);
        } else {
            throw new Error(`${response.status} ${response.statusText}`);
        }

    };

    const handleSignOut = () => {
        sessionStorage.removeItem("access_token");
        setIsLoggedIn(false);
        navigate("/");
        setCurrentUser(null);
    };

    const handleSignUp = async (username: string, email: string, password: string) => {
        clearErrors();

        if (!email || !password) {
            setError("Brukernavn og passord kreves");
            return;
        }


        const url = `${BACKEND_REST_URL}/api/auth/new`;
        const body = { user_name: username, email, password };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            });

            await handleAuthResponse(response, "Registrering mislyktes");
            await handleSignIn(email, password);
            navigate("/");
        } catch (error: any) {
            setError(error.message || "Registrering mislyktes");
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                signIn: handleSignIn,
                signOut: handleSignOut,
                signUp: handleSignUp,
                userData,
                currentUser,
                error,
                defaultLanguage,
                signInError,
                passwordMismatch,
                setPasswordMismatch,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
