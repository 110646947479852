import React, { useEffect, useState } from 'react';
import { RecordData, BodyUpdate, TranslationResult, RecordDataPost } from '@/types/types';
import { QuillEditor, QuillTranslation, QuillViewer } from '@/components/simple/TextEditor';
import { TagGrid } from '@/components/composed/Activity/TagGrid';
import { useLanguage } from '@/contexts/LanguageContext';
import { useCreateActivity } from '@/contexts/CreateActivityContext';
import { useOptions } from '@/contexts/OptionsContext';
import { useRecords } from '@/contexts/RecordsContext';
import { MediaBox } from '@/components/composed/MediaBox';
import { HeaderWithBack } from '@/components/composed/HeaderWithBack';
import { useTranslation } from 'react-i18next';
import { translateText } from '@/utils/translate';
import { Cancel, Edit, LockOpen, Save } from '@mui/icons-material';
import { useAuth } from '@/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Popper } from '@/components/simple/Popper';

interface ActivityViewerProps {
    record: RecordData;
}


export const ActivityViewer: React.FC<ActivityViewerProps> = ({ record }) => {
    const { t } = useTranslation();
    const { isEditing } = useRecords();
    const { isLoggedIn } = useAuth();
    const { skillLevelOptions, focusGroupOptions, categoryOptions, branchOptions, environmentOptions, disabilityOptions, equipmentOptions } = useOptions();
    const { currentLanguage } = useLanguage();
    const { activityPost, setActivityPost, isChecked, handleInputChange, handlePostBody } = useCreateActivity();
    const navigate = useNavigate();
    const [translatedTitle, setTranslatedTitle] = useState<TranslationResult | null>(null);
    const [translatedDescription, setTranslatedDescription] = useState<TranslationResult | null>(null);

    const [isTitleTranslating, setIsTitleTranslating] = useState(false);
    const [isDescriptionTranslating, setIsDescriptionTranslating] = useState(false);

    const [isEditingTranslation, setIsEditingTranslation] = useState(false);
    const notOriginalLanguage = record.original_language_id !== currentLanguage?.id;
    const notOwnLanguage = record.language_id !== currentLanguage?.id;

    useEffect(() => {
        setActivityPost({
            ...record,
            language_id: currentLanguage?.id || 1,
            disabilities: record.disabilities.map(d => d.name),
            skill_levels: record.skill_levels.map(s => s.name),
            focus_groups: record.focus_groups.map(f => f.name),
            categories: record.categories.map(c => c.name),
            branches: record.branches.map(b => b.name),
            environments: record.environments.map(e => e.name),
            equipment: record.equipment.map(e => e.name)
        } as RecordDataPost);
    }, [record, currentLanguage]);




    const handleSaveTranslation = async (activityId: number, languageId: number) => {
        console.log("save");
        if (!translatedTitle?.text || !translatedDescription?.text) {
            return;
        }
        const body: BodyUpdate = {
            name: translatedTitle.text,
            description: translatedDescription.text,
            adaptations: record.adaptations || "",
            tips: record.tips || "",
            videoUrl: record.videoUrl || "",
            language_id: languageId,
        }
        await handlePostBody(body, activityId);
        navigate(`/activity/${activityId}/${languageId}`);
        setIsEditingTranslation(false);
    }

    const handleTranslationClick = async () => {
        // Translate Title
        setIsTitleTranslating(true);
        const titleTranslation = await translateText(record.name || "", currentLanguage?.code || "");
        setTranslatedTitle(titleTranslation);
        setIsTitleTranslating(false);

        // Translate Description
        setIsDescriptionTranslating(true);
        const descriptionTranslation = await translateText(record.description || "", currentLanguage?.code || "");
        setTranslatedDescription(descriptionTranslation);
        setIsDescriptionTranslating(false);
        setIsEditingTranslation(true);
    };



    return (
        <div className="container mx-auto px-4 max-w-7xl">
            <div className="relative">
                <div className="container mx-auto px-4 max-w-7xl">
                    <div className="relative">
                        {notOriginalLanguage && notOwnLanguage && (
                            <div className="border-2 border-green-400 p-4 rounded-lg mb-4">
                                {/* When Translation is not yet done */}
                                {!translatedTitle?.text && !translatedDescription?.text ? (
                                    <div className="flex items-center justify-between">
                                        <p className="text-gray-700 text-sm font-medium">
                                            {t("common.translate.notOwn")}
                                        </p>
                                        {isLoggedIn ? <button
                                            onClick={handleTranslationClick}
                                            className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg shadow-md transition duration-300 ease-in-out"
                                            disabled={isTitleTranslating || isDescriptionTranslating}
                                        >
                                            {isTitleTranslating || isDescriptionTranslating ? (
                                                <span className="animate-pulse">Translating...</span>
                                            ) : (
                                                "Translate"
                                            )}
                                        </button> : <button
                                            onClick={() => navigate("/signin")}
                                            className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-lg shadow-md transition duration-300 ease-in-out"
                                        >
                                            <LockOpen />
                                            {t("common.translate.signInToTranslate")}
                                        </button>}
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-end space-x-4">
                                        <div className="relative group cursor-pointer">
                                            <Popper
                                                text="Edit the translation of the activity"
                                            >
                                            {isEditingTranslation ? <Cancel onClick={() => setIsEditingTranslation(false)} /> : <Edit onClick={() => setIsEditingTranslation(true)} />}
                                            </Popper>
                                        </div>

                                        {/* Save Translation Button */}
                                        {isEditingTranslation && (
                                            <button
                                                onClick={() => handleSaveTranslation(record.id, currentLanguage?.id || 0)}
                                                className="flex items-center px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg shadow-md transition duration-300"
                                            >
                                                <Save />
                                                Save
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Title Section */}
                        <div>
                            <HeaderWithBack>

                                <h2 className="text-2xl font-semibold">
                                    {isEditingTranslation ? (
                                        <input
                                            type="text"
                                            value={translatedTitle?.text || ""}
                                            onChange={(e) => setTranslatedTitle({ text: e.target.value, uncertain_words: [] })}
                                            className="border border-gray-300 rounded-md px-2 py-1 w-full"
                                        />
                                    ) : isEditing ? (
                                        <input
                                            type="text"
                                            value={activityPost.name}
                                            onChange={(e) => handleInputChange('name', e.target.value)}
                                            className="border border-gray-300 rounded-md px-2 py-1 w-full"
                                        />
                                    ) : (
                                        translatedTitle?.text || record.name
                                    )}
                                </h2>
                            </HeaderWithBack>
                        </div>

                        {/* Description Section */}
                        <div className="prose mt-4">
                            {isEditingTranslation ? (
                                <QuillTranslation
                                    value={translatedDescription?.text || activityPost.description || ''}
                                    onChange={(content) => handleInputChange('description', content)}
                                />
                            ) : isEditing ? (
                                <QuillEditor value={activityPost.description} onChange={(content) => handleInputChange('description', content)} />
                            ) : (
                                <QuillViewer content={translatedDescription?.text || record.description || ''} />
                            )}
                        </div>
                    </div>
                </div>

            </div>

            {
                isEditing &&
                <>
                    <MediaBox />
                    <div className="border-t border-gray-400 my-8"></div>
                </>
            }
            {
                record.videoUrl && !isEditing && (
                    <>
                        <div className="aspect-w-16 aspect-h-9 my-8">
                            <iframe
                                src={`https://www.youtube.com/embed/${record.videoUrl.split('v=')[1]?.split('&')[0]}`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="w-full h-full rounded-lg"
                            ></iframe>
                        </div>
                        <div className="border-t border-gray-400 my-8"></div>
                    </>
                )
            }

            {/* Tags */}
            {isEditing && <TagGrid
                activity={{
                    skill_levels: skillLevelOptions,
                    focus_groups: focusGroupOptions,
                    categories: categoryOptions,
                    branches: branchOptions,
                    environments: environmentOptions,
                    disabilities: disabilityOptions,
                    equipment: equipmentOptions
                }}
                isEditable={isEditing}
                isChecked={isChecked}
                onChange={(section, id) => handleInputChange(section, id.toString())}
                addableSections={['categories']}
            />}
            {!isEditing && <TagGrid
                activity={record}
                isEditable={isEditing}
                // isChecked={isChecked}
                onChange={(section, id) => handleInputChange(section, id.toString())}
                addableSections={['categories']}
            />}
        </div >
    );
};