import { TextField } from "@mui/material";
import { KeyWord } from "@/types/types";
import { Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip } from "@/components/simple/Chip";
interface MultiSelectProps {
    label: string;
    options: KeyWord[];
    selectedValues: string[];
    onChange: (value: string[]) => void;
    removeFromSelection: (value: string) => void;
    color: string;
  }
  
export const MultiSelect: React.FC<MultiSelectProps> = ({
    label,
    options,
    selectedValues,
    onChange,
    removeFromSelection,
    color,
  }) => {
    const { t } = useTranslation();
    return (
        <Autocomplete
        multiple
        options={options.map((option) => option.name)}
        value={selectedValues}
        onChange={(_event, newValue) => onChange(newValue)}
        renderTags={(value: string[], _getTagProps) =>
          value.map((option, index) => (
            <Chip
              item={{ name: option, id: index }}
              color={color}
              onChange={(value) => removeFromSelection(value)}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={t("common.search")}
          />
        )}
        style={{ width: "100%" }}
      />
    );
  };