import React from "react";
import { useAuth } from "@/contexts/AuthContext";

const MyPage: React.FC = () => {
  const { signOut } = useAuth();
  return (
    // sign out
    <div className="flex flex-col items-center justify-center ">
      <button className="bg-red-500 text-white px-16 py-8 rounded" onClick={() => {
        signOut();
      }}>
        Sign Out
      </button>
    </div>
  );
};

export default MyPage;
