// GlobalProvider.tsx
import React, { ReactNode } from "react";
import { SearchProvider } from "./SearchContext";
import { AuthProvider } from "./AuthContext";
import { OptionsProvider } from "./OptionsContext";
import { RecordsProvider } from "./RecordsContext";
import { CreateActivityProvider } from "./CreateActivityContext";
import { SnackbarProvider } from "./SnackbarProvider";
import { LanguageProvider } from "./LanguageContext";
import { TranslateProvider } from "./TranslateContext";
import { SessionsProvider } from "./SessionContext";
import { CreateSessionProvider } from "./CreateSessionContext";

interface GlobalProviderProps {
  children: ReactNode;
}

const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  return (
    <AuthProvider>
      <LanguageProvider>
        <TranslateProvider>
          <RecordsProvider>
            <SessionsProvider>
              <CreateActivityProvider>
                <CreateSessionProvider>
                    <OptionsProvider>
                      <SearchProvider>
                        <SnackbarProvider>{children}</SnackbarProvider>
                      </SearchProvider>
                    </OptionsProvider>
                </CreateSessionProvider>
              </CreateActivityProvider>
            </SessionsProvider>
          </RecordsProvider>
        </TranslateProvider>
      </LanguageProvider>
    </AuthProvider>
  );
};

export default GlobalProvider;
