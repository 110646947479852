import { ArrowDropDown } from "@mui/icons-material";

import { LanguageSharp } from "@mui/icons-material";

import { useState } from "react";
import { Language } from "@/types/types";
import { useTranslation } from "react-i18next";
import { useLanguage } from "@/contexts/LanguageContext";
import LanguageModal from "@/components/simple/LanguageModal";

export const LanguageSelector = ({ currentLanguage, setActivityLanguage }: { currentLanguage: Language | null, setActivityLanguage: (language: Language) => void }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const handleLanguageChange = (languageCode: string) => {
        const { languages } = useLanguage();
        const selectedLanguage = languages.find(lang => lang.code === languageCode);
        if (selectedLanguage) {
            setActivityLanguage(selectedLanguage);
        }
    };

    return (
        <>
            <button
                onClick={() => setIsModalOpen(true)}
                className="ml-auto flex items-center gap-2 px-4 py-2 border rounded-md hover:bg-gray-50"
            >
                <LanguageSharp className="text-gray-600" />
                <div>{currentLanguage?.name || t('common.selectLanguage')}</div>
                <ArrowDropDown className="text-gray-600" />
            </button>

            <LanguageModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onLanguageChange={handleLanguageChange}
            />
        </>
    );
};