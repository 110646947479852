import React, { createContext, useContext, useState, useCallback } from 'react';
import { SessionRecordData, SearchData } from '@/types/types';
import { BACKEND_REST_URL } from '@/main';

interface SessionsContextType {
    session: SessionRecordData | null;
    setSession: (session: SessionRecordData | null) => void;
    sessions: SessionRecordData[];
    loading: boolean;
    error: string | null;
    total: number;
    fetchSessions: (skip: number, limit: number, searchData: SearchData) => Promise<void>;
    fetchSession: (id: number, language_id: number) => Promise<void>;
    clearSessions: () => void;
}

const SessionsContext = createContext<SessionsContextType | undefined>(undefined);


export const SessionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [session, setSession] = useState<SessionRecordData | null>(null);
    const [sessions, setSessions] = useState<SessionRecordData[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [total, setTotal] = useState(0);

    const fetchSession = useCallback(async (id: number, language_id: number) => {
        const url = `${BACKEND_REST_URL}/api/sessions/${id}/${language_id}`;
        setLoading(true);
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }
            const data = await response.json();
            setSession(data);
            return data;
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
            setSession(null);
            throw err;
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchSessions = useCallback(async (skip: number, limit: number, searchData: SearchData) => {
        const url = `${BACKEND_REST_URL}/api/sessions/search?skip=${skip}&limit=${limit}`;
        setLoading(true);
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(searchData),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }

            const data = await response.json();
            const sessionData: SessionRecordData[] = [];
            data.sessions.forEach(async (session: SessionRecordData) => {
                const data: SessionRecordData = await fetchSession(session.id, session.original_language_id);
                sessionData.push({ ...session, ...data });
            });
            console.log(sessionData);
            console.log(data)

            setSessions((prevSessions) =>
                skip === 0 ? sessionData : [...prevSessions, ...sessionData]
            );
            setTotal(data.total);
            // setSessions((prevSessions) =>
            //     skip === 0 ? data.sessions : [...prevSessions, ...data.sessions]
            // );
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    }, []);

    const clearSessions = useCallback(() => {
        setSessions([]);
        setTotal(0);
        setError(null);
    }, []);

    return (
        <SessionsContext.Provider
            value={{
                sessions,
                loading,
                error,
                session,
                setSession,
                total,
                fetchSessions,
                fetchSession,
                clearSessions,
            }}
        >
            {children}
        </SessionsContext.Provider>
    );
};

export const useSessions = () => {
    const context = useContext(SessionsContext);
    if (context === undefined) {
        throw new Error('useSessions must be used within a SessionsProvider');
    }
    return context;
};
