import { useTranslation } from "react-i18next";
import { useRecords } from "@/contexts/RecordsContext";
import { useSearchContext } from "@/contexts/SearchContext";
import { useEffect } from "react";
import { Section, RecordData } from "@/types/types";
import SearchActivities from "@/components/composed/ActivitySearch";

export const ActivitySelectModal: React.FC<{
    open: boolean;
    section: Section;
    onClose: () => void;
    onSelect: (activity: RecordData) => void;
    language_id: number;
}> = ({ open, section, onClose, language_id, onSelect }) => {
    const { t } = useTranslation();
    const { records, fetchRecords } = useRecords();
    const { searchData } = useSearchContext();

    useEffect(() => {
        if (open) {
            fetchRecords(0, 100, {...searchData,show_activities:true }, language_id);
        }
    }, [open, searchData, language_id]);

    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center">
                <div className="fixed inset-0 bg-black opacity-30" onClick={onClose}></div>
                <div className="relative bg-white rounded-lg p-6 w-full max-w-2xl">
                    <h2 className="text-xl font-semibold mb-4">{t('createSession.select_activities')}</h2>
                    <SearchActivities activities={records} onSelect={onSelect} selectedActivities={section.activities} />
                    <div className="mt-4 flex justify-end">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
                        >
                            {t('common.close')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};