import SignUpForm from "@/components/simple/SignUpForm";


export const SignUpPage = () => {
  return (
    <div className="flex justify-center items-start min-h-screen ">
        <div className="w-full max-w-md p-8 space-y-6 rounded ">
            <SignUpForm />
        </div>
    </div >
  );
};