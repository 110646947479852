export const RecordMini: React.FC<{
    id: number;
    name: string;
    onClick: () => void;
}> = ({ id, name, onClick }) => {
    return (
        <div
            className="flex items-center justify-between w-full p-3 bg-white rounded-lg shadow-sm hover:bg-gray-50 cursor-pointer border border-gray-200"
            onClick={onClick}
            id={id.toString()}
        >
            <div className="flex items-center gap-2">
                <span className="text-sm font-medium text-gray-900">{name}</span>
            </div>
            <button
                className="p-1 hover:bg-gray-100 rounded-full"
                onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </button>
        </div>
    );
}