import { useTranslation } from "react-i18next";
import { Section, RecordData, SessionRecordData } from "@/types/types";
import { useState } from "react";
import { SessionSection } from "./SessionSection";
import { Disclosure } from "@headlessui/react";


interface SessionSectionCreateProps {
    session: SessionRecordData;
    language_id: number;
    onChange?: (section: Section, value: string) => void;
    setSession: (session: SessionRecordData) => void;
}
export const SessionSectionCreate: React.FC<SessionSectionCreateProps> = ({ session, language_id, setSession }) => {
    const { t } = useTranslation();
    const [activeSection, setActiveSection] = useState(0);

    const addNewSection = () => {
        const newSection = { id: session.sections.length, title: '', activities: [] };
        setSession({ ...session, sections: [...session.sections, newSection] });
        setActiveSection(session.sections.length);
    };

    const handleSectionChange = (section: number, value: string) => {
        setSession({ ...session, sections: session.sections.map((s: Section, index: number) =>
            index === section
                ? { ...s, title: value }
                : s
        ) });
    };

    const handleSelect = (activity: RecordData) => {
        const currentSection = session.sections[activeSection];
        const activityExists = currentSection.activities.some((a: RecordData) => a.id === activity.id);

        const updatedActivities = activityExists
            ? currentSection.activities.filter((a: RecordData) => a.id !== activity.id)
            : [...currentSection.activities, activity];

        setSession({
            ...session,
            sections: session.sections.map((s: Section, index: number) =>
                index === activeSection
                    ? { ...s, activities: updatedActivities }
                    : s
            )
        });
    }

    return (
        <div className="w-full pt-8 px-4">
            <div className="mx-auto w-full space-y-4">
                <Disclosure>
                    {session.sections.map((section: Section, index: number) => (
                        <SessionSection
                            key={index}
                            sessionSection={section}
                            sectionCount={index}
                            onChange={handleSectionChange}
                            language_id={language_id}
                            onSelect={handleSelect}
                            setActiveSection={setActiveSection}
                        />
                    ))}
                </Disclosure>

                <button
                    onClick={addNewSection}
                    className="w-full rounded-xl border-2 border-dashed border-gray-300
                             p-4 text-gray-500 hover:border-gray-400 hover:text-gray-600"
                >
                    + {t('createSession.add_new_section')}
                </button>
            </div>
        </div>
    );
}