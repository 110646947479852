import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Section, RecordData } from "@/types/types";
import { RecordMini } from "@/components/simple/RecordMini";
import { InputWithDescription } from "@/components/simple/InputWithDescription";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ExpandMore } from "@mui/icons-material";
import { ActivitySelectModal } from "@/components/composed/Session/ActivitySelectModal";
import { useCreateSession } from "@/contexts/CreateSessionContext";

export const SessionSection: React.FC<{
    sessionSection: Section;
    sectionCount: number;
    onChange: (section: number, value: string) => void;
    language_id: number;
    onSelect: (activity: RecordData) => void;
    setActiveSection: (index: number) => void;
}> = ({ sessionSection, sectionCount, onChange, language_id, onSelect, setActiveSection }) => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const { errors } = useCreateSession();

    return (
        <Disclosure defaultOpen>
            {({ open }) => (
                <div className="rounded-xl bg-gray-100 shadow-md" onClick={() => setActiveSection(sectionCount)}>
                    <DisclosureButton className="w-full">
                        <div className="flex justify-between items-center p-6">
                            <div className="flex items-center gap-2">
                                <span className="text-lg font-medium text-gray-700 rounded-full border-2 border-gray-300 p-2 h-8 w-8 flex items-center justify-center">
                                    {sectionCount + 1}
                                </span>
                                <InputWithDescription
                                    label={t('createSession.sectionTitle')}
                                    value={sessionSection.title}
                                    onChange={(value) => onChange(sectionCount, value)}
                                    description={t('createSession.sectionTitlePlaceholder', { example: t(`createSession.SectionTitlePlaceholderExamples.${sectionCount}`) })}
                                    hasError={!!errors.sections}
                                />
                            </div>
                            <ExpandMore
                                className={`transform transition-transform ${open ? "rotate-180" : ""
                                    }`}
                            />
                        </div>
                    </DisclosureButton>
                    <DisclosurePanel>
                        <div className="px-6 pb-6">
                            <div
                                className="min-h-24 rounded border-2 border-dashed border-gray-300 p-4 text-gray-500 cursor-pointer hover:border-gray-400 hover:text-gray-600"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setModalOpen(true);
                                    setActiveSection(sectionCount);
                                }}
                            >
                                {!sessionSection.activities.length ? t('createSession.drop_activities_here') : <div className="flex flex-col gap-2">
                                    {sessionSection.activities.map((activity: RecordData) => (
                                        <div key={activity.id} className="flex items-center gap-2 ">
                                            <RecordMini key={activity.id} id={activity.id} name={activity.name as string || ""} onClick={() => onSelect(activity)} />
                                        </div>
                                    ))}
                                </div>


                                }
                            </div>
                            <ActivitySelectModal
                                open={modalOpen}
                                section={sessionSection}
                                onClose={() => setModalOpen(false)}
                                language_id={language_id}
                                onSelect={onSelect}
                            />
                        </div>


                    </DisclosurePanel>
                </div>
            )}
        </Disclosure>
    );
};