import SignInForm from "@/components/simple/SignInForm";
import { useAuth } from "@/contexts/AuthContext";
import { useTranslation } from "react-i18next";


export const SignInPage = () => {
  const {
    isLoggedIn,
    signOut,
  } = useAuth();
  const { t } = useTranslation();

  return (
    <div className="flex justify-center items-start min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 rounded ">
        {!isLoggedIn ? (
          <SignInForm
          />
        ) : (
          <button
            className="w-full py-2 mt-3 mb-2 text-white bg-red-500 rounded"
            onClick={signOut}
          >
            {t("common.signOut")}
          </button>
        )}
      </div>
    </div>
  );
};

export default SignInPage;
