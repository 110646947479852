import { useState } from "react";

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useTranslation } from "react-i18next";

export const YouTubeUrlModal = ({ isOpen, onClose, onSubmit }: { isOpen: boolean, onClose: () => void, onSubmit: (url: string) => void }) => {
    const [youtubeUrl, setYoutubeUrl] = useState("");
    const { t } = useTranslation();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(youtubeUrl);
        setYoutubeUrl("");
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <DialogPanel className="bg-white rounded-lg p-6 w-full max-w-md">
                    <DialogTitle as="h2" className="text-lg font-medium mb-4">
                        {t('createActivity.addYouTubeVideo')}
                    </DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="url"
                            value={youtubeUrl}
                            onChange={(e) => setYoutubeUrl(e.target.value)}
                            placeholder="https://www.youtube.com/watch?v=..."
                            className="w-full p-2 border border-gray-300 rounded mb-4"
                            required
                            pattern="^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+"
                        />
                        <div className="flex justify-end gap-2">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
                            >
                                {t('common.cancel')}
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                {t('common.add')}
                            </button>
                        </div>
                    </form>
                </DialogPanel>
            </div>
        </Dialog>
    );
};