import { SessionRecordData } from "@/types/types";
import { TagGrid } from "@/components/composed/Activity/TagGrid";
import { useOptions } from "@/contexts/OptionsContext";

interface SessionTagGridProps {
    session: SessionRecordData;
    onChange?: (section: string, value: string) => void;
    isChecked?: (key: string, value: string) => boolean;
    errors?: { [key: string]: string };
}

export const SessionTagGrid: React.FC<SessionTagGridProps> = ({ session, onChange, isChecked, errors }) => {
    const { categoryOptions, branchOptions, } = useOptions();
    const keywords = {
        id: session.id,
        name: session.name,
        branches: branchOptions,
        categories: categoryOptions,
        skill_levels: [],
        focus_groups: [],
        environments: [],
        disabilities: [],
        equipment: [],
    };

    return (
        <TagGrid
            activity={keywords}
            isEditable={true}
            onChange={onChange}
            isChecked={isChecked}
            visibleSections={['branches', 'categories']}
            addableSections={['branches', 'categories']}
            errors={errors}
        />
    );
}
