import React, { createContext, useContext, useState, useCallback, } from 'react';
import { RecordData, SearchData, SessionRecordData } from '@/types/types';
import { BACKEND_REST_URL } from '@/main';

interface RecordsContextType {
    record: RecordData | SessionRecordData | null;
    setRecord: (record: RecordData | SessionRecordData | null) => void;
    records: RecordData[];
    otherBodies: RecordData[];
    loading: boolean;
    error: string | null;
    total: number;
    otherTotal: number;
    fetchRecords: (skip: number, limit: number, searchData: SearchData, language_id: number) => Promise<void>;
    fetchRecord: (id: number, activityOrSession: "activities" | "sessions", language_id: number) => Promise<void>;
    clearRecords: () => void;
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    handleDelete: () => void;
}

const RecordsContext = createContext<RecordsContextType | undefined>(undefined);

export const RecordsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [record, setRecord] = useState<RecordData | SessionRecordData | null>(null);
    const [records, setRecords] = useState<RecordData[]>([]);
    const [otherBodies, setOtherBodies] = useState<RecordData[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [total, setTotal] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [otherTotal, setOtherTotal] = useState(0);

    const FetchRecordCall = async (id: number, activityOrSession: "activities" | "sessions", language_id: number) => {
        const url = `${BACKEND_REST_URL}/api/${activityOrSession}/${id}/${language_id}`;
        setLoading(true);
        let data;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }
            data = await response.json();
            return data;
        } catch (err) {
            // setError(err instanceof Error ? err.message : 'An error occurred');
            return null;
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async () => {
        // TODO: delete activity
        if (!record) {
            return;
        }
        try {
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const url = `${BACKEND_REST_URL}/api/activities/${record.id}`;
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }
            setRecord(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        }
    }

    const fetchRecord = useCallback(async (id: number, activityOrSession: "activities" | "sessions", language_id: number) => {
        try {
            const data = await FetchRecordCall(id, activityOrSession, language_id);
            setRecord(data);
            return data;
        } catch (err) {
            setRecord(null);
            throw err;
        }
    }, []);

    const fetchRecords = useCallback(async (skip: number, limit: number, searchData: SearchData, language_id: number) => {

        const activityOrSession = searchData.show_activities ? "activities" : "sessions";
        const url = `${BACKEND_REST_URL}/api/${activityOrSession}/search?skip=${skip}&limit=${limit}`;

        setLoading(true);
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(searchData),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }

            const data = await response.json();
            let total = data.total;
            let other_total = 0;
            let bodies: RecordData[] = [];
            let other_bodies: RecordData[] = []
            const params = new URLSearchParams();
            let original_language_ids: number[] = []
            if (total > 0) {
                params.append('language_id', language_id.toString());
                data[activityOrSession].forEach((record: RecordData) => {
                    // if (record.original_language_id === language_id) {
                    params.append('activity_ids', record.id.toString());
                    // } else {
                    //     original_language_ids.push(record.original_language_id!);
                    //     total--;
                    //     other_total++;
                    // }
                });
                const bodies_response = await fetch(`${BACKEND_REST_URL}/api/${activityOrSession}/bodies?${params.toString()}`);
                bodies = await bodies_response.json();
                if (bodies.length > 0) {
                    bodies.forEach((body: RecordData) => {
                        body.language_id = language_id;
                    });
                    // find how many activities are not in the bodies

                    const activities_not_in_bodies = data[activityOrSession].filter((record: RecordData) => !bodies.some((body: RecordData) => body.id === record.id));
                    console.log(activities_not_in_bodies);
                    original_language_ids = activities_not_in_bodies.map((record: RecordData) => record.original_language_id!);
                    original_language_ids = [...new Set(original_language_ids)];

                    total -= activities_not_in_bodies.length;
                    other_total = original_language_ids.length;

                } else { // no bodies found on your language id.
                    original_language_ids = data[activityOrSession].map((record: RecordData) => record.original_language_id!);
                    original_language_ids = [...new Set(original_language_ids)];
                    other_total = original_language_ids.length;
                }

                const otherBodiesPromises = original_language_ids.map(async (id: number) => {
                    params.delete('activity_ids');
                    params.delete('language_id');
                    data[activityOrSession].forEach((record: RecordData) => {
                        if (record.original_language_id === id) {
                            params.append('activity_ids', record.id.toString());
                        }
                    });
                    params.append('language_id', id.toString());
                    const bodies_response = await fetch(`${BACKEND_REST_URL}/api/${activityOrSession}/bodies?${params.toString()}`);
                    return bodies_response.json();
                });
                const otherBodiesResults = await Promise.all(otherBodiesPromises);
                other_bodies = otherBodiesResults.flat();
                if (other_bodies.length > 0) {
                    other_bodies.forEach((body: RecordData) => {
                        if (body.original_language_id) {
                            body.language_id = body.original_language_id;
                        }
                    });
                }
            }
            else {
                bodies = [];
                other_bodies = [];
            }
            setTotal(total);
            setOtherTotal(other_total);



            setRecords((prevRecords) =>
                skip === 0
                    ? bodies
                    : [...prevRecords, ...bodies]
            )
            setOtherBodies((prevOtherBodies) =>
                skip === 0
                    ? other_bodies
                    : [...prevOtherBodies, ...other_bodies]
            )
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    }, []);

    const clearRecords = useCallback(() => {
        setRecords([]);
        setTotal(0);
        setError(null);
    }, []);

    return (
        <RecordsContext.Provider
            value={{
                records,
                loading,
                error,
                record,
                setRecord,
                total,
                isEditing,
                setIsEditing,
                fetchRecords,
                fetchRecord,
                clearRecords,
                otherBodies,
                otherTotal,
                handleDelete,
            }}
        >
            {children}
        </RecordsContext.Provider>
    );
};

export const useRecords = () => {
    const context = useContext(RecordsContext);
    if (context === undefined) {
        throw new Error('useRecords must be used within a RecordsProvider');
    }
    return context;
}; 