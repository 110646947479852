import React, { createContext, useContext, useState, useEffect } from 'react';
import { KeyWord, SessionRecordData } from '@/types/types';
import { BACKEND_REST_URL } from '@/main';

interface CreateSessionContextType {
    session: SessionRecordData;
    setSession: (session: SessionRecordData) => void;
    handleInputChange: (name: string, value: string) => void;
    handlePost: (session: SessionRecordData, language_id: number) => Promise<void>;
    validateSession: () => boolean;
    errors: { [key: string]: string };
    setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
}

const CreateSessionContext = createContext<CreateSessionContextType | undefined>(undefined);

export const CreateSessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [session, setSession] = useState<SessionRecordData>({
        description: "",
        name: "",
        branches: [],
        categories: [],
        sections: [],
        title: "",
        id: 0,
        last_edited: "",
        is_activity: false,
        original_language_id: 0
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        console.log(session);
    }, [session]);
    const handleInputChange = (name: string, value: string) => {
        if (Array.isArray(session[name as keyof SessionRecordData])) {
            if ((session[name as keyof SessionRecordData] as any[]).some((item: KeyWord) => item.name === value)) {
                setSession(prev => ({
                    ...prev,
                    [name]: (prev[name as keyof SessionRecordData] as any[]).filter((item: KeyWord) => item.name !== value)
                }));
            } else {
                setSession(prev => ({
                    ...prev,
                    [name]: [...(prev[name as keyof SessionRecordData] as any[]), { id: (prev[name as keyof SessionRecordData] as any[]).length, name: value }]
                }));
            }
        } else {
            setSession(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const validateSession = (): boolean => {
        const newErrors: { [key: string]: string } = {};
        const requiredFields = ['name', 'description', 'sections', 'branches', 'categories'];

        requiredFields.forEach(field => {
            const value = session[field as keyof SessionRecordData];
            if (!value || (Array.isArray(value) && value.length === 0) || value === "") {
                newErrors[field] = `${field} is required`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePost = async (session: SessionRecordData, language_id: number) => {
        try {
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const transformedData = {
                title: session.title || session.name,
                description: session.description,
                branches: session.branches.map(branch => branch.name),
                categories: session.categories.map(category => category.name),
                sections: session.sections.length,
                language_id: language_id,
                section_titles: session.sections.map(section => section.title),
                selected_activities: session.sections.map(section =>
                    section.activities?.map(activity => ({ id: activity.id })) || []
                )
            };

            const response = await fetch(`${BACKEND_REST_URL}/api/sessions/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(transformedData),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const sessionData = await response.json();
            return sessionData;
        } catch (error) {
            console.error('Error posting session:', error);
            throw error;
        }
    };

    return (
        <CreateSessionContext.Provider value={{
            session,
            setSession,
            handleInputChange,
            handlePost,
            validateSession,
            errors,
            setErrors
        }}>
            {children}
        </CreateSessionContext.Provider>
    );
};

export const useCreateSession = () => {
    const context = useContext(CreateSessionContext);
    if (context === undefined) {
        throw new Error('useCreateSession must be used within a CreateSessionProvider');
    }
    return context;
};