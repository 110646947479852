import { BACKEND_REST_URL } from "@/main";
import { TranslationResult } from "@/types/types";
export async function translateText(text: string, languageId: string): Promise<TranslationResult> {
    const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
    if (!token) {
        throw new Error('No authentication token found');
    }

    // 1. Initiate translation
    const response = await fetch(`${BACKEND_REST_URL}/api/translation/`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ text, language_id: languageId })
    });

    const threadId = await response.json();
    if (!threadId) throw new Error("No thread ID received from the server.");

    // 2. Poll for results
    return new Promise((resolve, reject) => {
        const pollInterval = setInterval(async () => {
            try {
                const statusResponse = await fetch(`${BACKEND_REST_URL}/api/translation/${threadId}`, {
                    headers: { "Authorization": `Bearer ${token}` },
                });
                
                if (statusResponse.status === 202) {
                    return; 
                }
                
                if (statusResponse.status === 200) {
                    clearInterval(pollInterval);
                    const result = await statusResponse.json();
                    resolve(result);
                }
            } catch (error) {
                clearInterval(pollInterval);
                reject(error);
            }
        }, 2000);
    });
} 