import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useAuth } from "@/contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { EnvelopeIcon, LockClosedIcon} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

type Inputs = {
  email: string;
  username: string;
  password: string;
};

const SignInForm: React.FC = () => {
  const { signIn } = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch
  } = useForm<Inputs>();
  const { t } = useTranslation();

  // Watch the email/username input field
  let isEmail = false;
  if (watch("username")) {
    const emailOrUsername = watch("username");
    isEmail = emailOrUsername.includes("@");
  }
  if (watch("email")) {
    const emailOrUsername = watch("email");
    isEmail = emailOrUsername.includes("@");
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await signIn(data.email || data.username, data.password);
    } catch (error) {
      if (error instanceof Error && error.message.includes("401")) {
        setError("password", { message: t("auth.loginError") });
      } else {
        setError("password", { message: t("common.error") });
      }
    }
  };
  
  return (
    <div className=" bg-gray-100 flex justify-center px-4">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-900">
          {t("common.signIn")}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t("auth.emailOrUsername")}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="email"
                type={isEmail ? "email" : "text"}
                placeholder={t("auth.emailOrUsername")}
                {...(isEmail
                  ? register("email", { required: t("common.emailRequired") })
                  : register("username", { required: t("common.usernameRequired") }))}
                className="input w-full pl-10 border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <EnvelopeIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            <ErrorMessage errors={errors} name="email" as="p" className="text-red-500 text-sm mt-1" />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              {t("auth.password")}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="password"
                type="password"
                placeholder={t("auth.password")}
                {...register("password", { required: t("common.passwordRequired") })}
                className="input w-full pl-10 border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            <ErrorMessage errors={errors} name="password" as="p" className="text-red-500 text-sm mt-1" />
          </div>

          {/* Sign In Button */}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {t("common.signIn")}
          </button>

          {/* Additional Links */}
          <div className="flex justify-between text-sm text-gray-600 mt-4">
            <a href="/forgot-password" className="hover:underline">
              {t("auth.forgotPassword")}
            </a>
            <Link to="/signup" className="hover:underline">
              {t("auth.createAccount")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignInForm;