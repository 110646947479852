import { useCreateActivity } from "@/contexts/CreateActivityContext";
import { QuillEditor } from "@/components/simple/TextEditor";
import { HeaderWithBack } from "@/components/composed/HeaderWithBack";
import { TitleInput } from "@/components/simple/TitleInput";
import { TagGrid } from "@/components/composed/Activity/TagGrid";
import { useOptions } from "@/contexts/OptionsContext";
import { useLanguage } from "@/contexts/LanguageContext";
import { LanguageSelector } from "@/components/composed/LanguageSelector";
import { MediaBox } from "@/components/composed/MediaBox";






export const ActivityCreate: React.FC = () => {
    const {
        activityPost,
        handleInputChange,
        errors,
        isChecked,
    } = useCreateActivity();

    const { skillLevelOptions, focusGroupOptions, categoryOptions, branchOptions, environmentOptions, disabilityOptions, equipmentOptions } = useOptions();


    const { activityLanguage, setActivityLanguage } = useLanguage();
    const errorBorder = !!errors.name || !!errors.description ? 'border-red-500' : 'border-gray-200';

    return (
        <div className="container mx-auto max-w-7xl ">
            <div className="container mx-auto px-4 max-w-7xl">

                <HeaderWithBack>
                    <TitleInput
                        name="name"
                        title={activityPost.name}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        hasError={!!errors.name}
                    />

                    <LanguageSelector currentLanguage={activityLanguage} setActivityLanguage={setActivityLanguage} />
                </HeaderWithBack>

                <div className="space-y-4">
                    <div className={`border-b-2 ${errorBorder}`}>

                        <QuillEditor
                            value={activityPost.description}
                            onChange={(value) => handleInputChange('description', value)}
                            hasError={!!errors.description}
                        />
                    </div>
                    <div className="flex w-full items-center justify-center border-4 border-gray-300 rounded-lg hover:cursor-pointer hover:bg-gray-100">
                        <MediaBox />
                    </div>
                    <TagGrid
                        activity={{
                            skill_levels: skillLevelOptions,
                            focus_groups: focusGroupOptions,
                            categories: categoryOptions,
                            branches: branchOptions,
                            environments: environmentOptions,
                            disabilities: disabilityOptions,
                            equipment: equipmentOptions
                        }}
                        isEditable={true}
                        errors={errors}
                        onChange={(section, id) => handleInputChange(section, id.toString())}
                        isChecked={isChecked}
                    />
                </div>
                {/* <div className="flex justify-end">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={() => handlePost(activityPost, [])}>
                        {t('common.save')}
                    </button>
                </div> */}
            </div>
        </div>
    );
};
