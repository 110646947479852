import React from "react";
import  RecordList from "@/components/composed/RecordList";
import SearchContainer from "@/components/composed/SearchContainer";

const Home: React.FC = () => {

  return (
    <div className="flex justify-center h-screen pb-16">
      <div className="w-full max-w-screen-lg px-4 flex flex-col">
        <div className="">
          <SearchContainer />
        </div>

        <div className="flex-1 py-16">
          <RecordList />
        </div>
      </div>
    </div>
  );
};

export default Home;