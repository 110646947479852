import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Add, Edit } from '@mui/icons-material';
import SaveFabComponent, { DeleteFabComponent, EditFabComponent, SaveChangesFabComponent, SaveSessionFabComponent } from '@/components/simple/Fab/FabComponents';
import { useTranslation } from 'react-i18next';
import { useRecords } from '@/contexts/RecordsContext';
import { useAuth } from '@/contexts/AuthContext';

const FloatingActionMenu: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const isCreateActivity = location.pathname === '/createActivity';
    const isHome = location.pathname === '/';
    const isActivityView = location.pathname.includes('/activity/');
    const { record, isEditing } = useRecords();
    const { currentUser } = useAuth();
    const isActivityOwner = record?.user_id === currentUser?.id;
    const canEdit = isActivityView && isActivityOwner;
    const { t } = useTranslation();
    const isCreateSession = location.pathname === '/createSession';
    if (!isHome && !isCreateActivity && !canEdit && !isCreateSession) {
        return null;
    }

    const homeActions = [
        {
            label: t('common.createActivity'),
            name: t('common.createActivityName'),
            icon: <Add fontSize="large" />,
            to: "/createActivity"
        },
        {
            label: t('common.createSession'),
            name: t('common.createSessionName'),
            icon: <Add fontSize="large" />,
            to: "/createSession"
        }
    ];

    const activityViewActions = [
        {
            label: t('common.editActivity'),
            name: isEditing ? t('common.saveChanges') : t('common.editActivityName'),
            component: isEditing ? SaveChangesFabComponent : EditFabComponent,
        },
        {
            label: t('common.deleteActivity'),
            name: t('common.deleteActivityName'),
            component: DeleteFabComponent,
        }
    ];

    const renderIcon = () => {
        if (isCreateActivity) {
            return <SaveFabComponent />;
        } else if (isCreateSession) {
            return <SaveSessionFabComponent />;
        } else if (isHome) {
            return <Add fontSize="large" />;
        } else if (canEdit) {
            return <Edit fontSize="large" />;
        }
    };
    const currentActions = canEdit ? activityViewActions : homeActions;

    const actionmap = currentActions.map((action, index) => {
        if ('to' in action) {
            return (
                <Link
                    to={action.to}
                    className="flex items-center justify-start space-x-2 bg-white hover:bg-gray-100 opacity-90 text-gray-800 rounded-full shadow-md px-3 py-2 transition-transform"
                    key={action.label}
                >
                    {action.icon} {action.label}
                </Link>
            );
        } else if ('component' in action) {
            const Component = action.component;
            return <Component key={`action-${index}`} />;
        }
    });
    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <div className={`fixed right-8 md:right-12 lg:right-16 xl:right-24 2xl:right-32 z-50 bottom-8`}>
            {!isCreateActivity && !isCreateSession && (
                <div className={`absolute bottom-full right-1/2 transform translate-x-1/2 mb-4 transition-all duration-300 ease-in-out ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}`}>
                    <div className="flex flex-col-reverse items-center space-y-4 space-y-reverse">
                        {actionmap}
                    </div>
                </div>
            )}
            <button
                onClick={toggleMenu}
                className="flex items-center justify-center w-16 h-16 bg-green-500 hover:bg-green-600 text-white rounded-full shadow-lg transition-transform"
            >
                {renderIcon()}
            </button>
        </div>
    );
};

export default FloatingActionMenu;
