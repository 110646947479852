import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

interface QuillEditorProps {
  value: string;
  onChange: (value: string) => void;
  hasError?: boolean;
}

export const QuillEditor: React.FC<QuillEditorProps> = ({
  value,
  onChange,
  hasError
}) => {
  const { t } = useTranslation();
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: "1" }, { header: "2" }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
          ],
        },
        placeholder: t("Quill.description"),
      });

      quillRef.current.on("text-change", () => {
        onChange(quillRef.current?.root.innerHTML || "");
      });

      quillRef.current.root.innerHTML = value;
    }
  }, []);

  return (
    <div
      ref={editorRef}
      className={`border border-gray-200 rounded-lg p-4 bg-white ${hasError ? 'border-red-500' : ''}`}

    />
  );
};
export const QuillTranslation: React.FC<{ value: string, onChange: (value: string) => void }> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: "1" }, { header: "2" }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
          ],
        },
        placeholder: t("Quill.description"),
      });

      quillRef.current.on("text-change", () => {
        onChange(quillRef.current?.root.innerHTML || "");
      });

      quillRef.current.root.innerHTML = value;
    }
  }, []);

  return (
    <div
      ref={editorRef}
      className="border border-gray-200 rounded-lg p-4 bg-white"

    />
  );;
};

interface QuillViewerProps {
  content: string;
}

export const QuillViewer: React.FC<QuillViewerProps> = ({ content }) => {
    const { t } = useTranslation();
    const editorRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill | null>(null);
  
    useEffect(() => {
      if (editorRef.current && !quillRef.current) {
        quillRef.current = new Quill(editorRef.current, {
          readOnly: true,
          theme: "snow",
          modules: {
            toolbar: false,
          },
        });
  
  
        quillRef.current.root.innerHTML = content;
        quillRef.current.disable();
      }
    }, []);
  

  return (
    <div>
      <div className="text-lg font-bold">{t('Quill.description')}</div>
      <div
        ref={editorRef}
        className="border border-gray-200 rounded-lg p-4 bg-white toolbar-hidden"
  
      />
    </div>
  );
};
