import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { SessionRecordData } from '@/types/types';
import { CategoryList } from "@/components/composed/CategoryList";
import { QuillViewer } from "@/components/simple/TextEditor";
import { HeaderWithBack } from "@/components/composed/HeaderWithBack";
import { SectionStepGrid } from "@/components/composed/Session/sectionStepGrid";

interface RecordCardProps {
    record: SessionRecordData;
}

export const SessionViewer: React.FC<RecordCardProps> = ({ record }) => {

    return (
        <Box component="div" className="container mx-auto p-4 lg:p-6">
            <div className="container mx-auto p-4 lg:p-6">
                <div className="text-center mb-6 lg:mb-8">
                    <HeaderWithBack>
                        <div>
                            <Typography variant="h5" className="font-semibold text-lg lg:text-2xl">
                                {record.title}
                            </Typography>
                        </div>
                    </HeaderWithBack>
                    <div className="text-center mb-6 lg:mb-8">
                        <QuillViewer content={record.description} />
                    </div>

                    <Divider className="py-4 my-6 lg:my-8">
                        <b>Informasjon</b>
                    </Divider>

                    <div className="grid  grid-cols-2 lg:grid-cols-2 gap-4 lg:gap-6">
                        <CategoryList categories={record.categories} />
                        {/* <SectionCount sections={record.sections.length} /> */}
                    </div>

                    <Divider className="py-4 mt-6 lg:mt-8">
                        <b>Øvelser</b>
                    </Divider>

                    {record.sections.map((section, index) => (
                        <SectionStepGrid
                            key={index}
                            sectionTitle={section.title}
                            selectedActivities={section.activities}

                        />
                    ))}

                </div>
            </div>
        </Box >
    );
};

export default SessionViewer;