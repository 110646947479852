import React, { createContext, useContext, useState } from "react";
import { BACKEND_REST_URL } from "@/main";
import { TranslateContextType, TranslationResult } from "@/types/types";


const TranslateContext = createContext<TranslateContextType | undefined>(
    undefined
);

export const TranslateProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [isTranslating, setIsTranslating] = useState(false);
    const [translation, setTranslation] = useState<TranslationResult | null>(null);
    const [status, setStatus] = useState<string | null>(null);

    const pollTranslationStatus = async (threadId: string) => {
        const interval = setInterval(async () => {
            try {
                const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const statusResponse = await fetch(`${BACKEND_REST_URL}/api/translation/${threadId}`, {
                    headers: { "Authorization": `Bearer ${token}` },
                });
                const statusData = await statusResponse.json();
                // if response is 202 not done.
                if (statusResponse.status === 202) {
                    return;
                }
                if (statusResponse.status === 200) {
                    setTranslation(statusData);

                    clearInterval(interval);
                    // await fetchResult(threadId);
                }

                setStatus(statusData.status);

            } catch (error) {
                console.error("Error polling translation status:", error);
                setStatus("Error checking translation status");
                clearInterval(interval);
                setIsTranslating(false);
            }
        }, 2000); // Poll every 2 seconds

        // Cleanup function to clear interval if component unmounts
        return () => clearInterval(interval);
    };

    const handleTranslate = async (text: string, languageId: string) => {
        setIsTranslating(true);
        setStatus("Starting translation...");
        setTranslation(null);

        try {
            // 1. Initiate the translation process
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            if (!token) {
                throw new Error('No authentication token found');
            }
            const response = await fetch(`${BACKEND_REST_URL}/api/translation/`, {
                method: "POST",
                headers: { "content-type": "application/json", "accept": "application/json", "Authorization": `Bearer ${token}` },
                body: JSON.stringify({ text, language_id: languageId })
            });

            const data = await response.json();
            const thread_id = data;

            if (!thread_id) throw new Error("No thread ID received from the server.");

            // Start polling for status
            pollTranslationStatus(thread_id);

        } catch (error) {
            console.error("Translation failed:", error);
            setStatus("Translation failed.");
            setIsTranslating(false);
        }
    };

    const clearTranslation = () => {
        setTranslation(null);
        setStatus(null);
    };

    return (
        <TranslateContext.Provider
            value={{ isTranslating, translation, status, handleTranslate, clearTranslation }}
        >
            {children}
        </TranslateContext.Provider>
    );
};

export const useTranslate = () => {
    const context = useContext(TranslateContext);
    if (!context) {
        throw new Error("useTranslate must be used within a TranslateProvider");
    }
    return context;
};