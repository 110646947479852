import { useTranslation } from "react-i18next";
import { KeyWord, TagSectionProps } from "@/types/types";
import { Chip, EditableChip } from "@/components/simple/Chip";
import { useState } from "react";


export const TagSection: React.FC<TagSectionProps> = ({
  title,
  icon,
  items,
  color,
}) => {
  const { t } = useTranslation();
  return (
    <div className="border p-4 rounded-lg shadow-lg bg-white hover:bg-gray-50 transition">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold flex items-center">
          <span className="text-2xl mr-2">{icon}</span>
          {title}
        </h3>
      </div>
      <div className="flex flex-wrap gap-2 mt-3">
        {items.length ? (
          items.map((item: KeyWord) => (
            <Chip
              key={item.id}
              item={item}
              color={color}
            />
          ))
        ) : (
          <p className="text-sm text-gray-500">{t('activityForm.noItemsAvailable')}</p>
        )}
      </div>
    </div>
  );
};

export const EditableTagSection: React.FC<TagSectionProps> = ({
  title,
  icon,
  items,
  color,
  onChange,
  isChecked,
  sectionKey,
  errors,
  addableSections = []
}) => {
  const { t } = useTranslation();
  const [newItem, setNewItem] = useState<KeyWord | null>(null);


  const onAdd = () => {
    setNewItem({
      id: items.length + 1,
      name: '',
    });
  };

  const handleConfirm = (value: string) => {
    if (newItem && onChange) {
      const Item = {
        id: items.length + 1,
        name: value,
      }
      setNewItem(Item);
      onChange(Item.name);
      items.push(Item);
      setNewItem(null);
    }
  };

  const handleCancel = () => {
    setNewItem(null);
  };

  return (
    <div className={`border p-4 rounded-lg shadow-lg bg-white hover:bg-gray-50 transition ${errors && errors[sectionKey] ? 'border-red-500' : ''}`}>
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold flex items-center">
          <span className="text-2xl mr-2">{icon}</span>
          {title}
        </h3>
        {addableSections.includes(sectionKey) && (
          <button
            onClick={onAdd}
            className="text-blue-600 hover:text-blue-800 disabled:text-gray-400"
          >
            +
          </button>
        )}
      </div>
      <div className={`flex flex-wrap gap-2 mt-3 `}>
        {items.length ? (
          items.map((item: KeyWord) => (
            <EditableChip
              key={item.id}
              item={item}
              color={color}
              onChange={onChange ? (value) => onChange(value) : undefined}
              checked={isChecked ? isChecked(item.name) : false}
            />
          ))
        ) : (
          <p className="text-sm text-gray-500">{t('activityForm.noItemsAvailable')}</p>
        )}
        {newItem && (
          <EditableChip
            item={newItem}
            color={color}
            onChange={() => { }}
            checked={false}
            isEditing={true}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </div>
    </div>
  );
};