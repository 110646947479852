import React from "react";
import { useSearchContext } from "@/contexts/SearchContext";
import { useRecords } from "@/contexts/RecordsContext";
import { SessionRecord } from "@/components/composed/Session/SessionRecord";
import { ActivityRecord } from "@/components/composed/Activity/ActivityRecord";


const RecordList: React.FC = () => {
  const { searchData } = useSearchContext();
  const { error } = useRecords();
  if (error) {
    return (
      <div className="flex justify-center my-16">
        <p className="text-xl text-red-600">{error}</p>
      </div>
    );
  }


  return (
    <section className="container mx-auto px-4 my-auto h-full">

      {searchData.show_activities ? <ActivityRecord /> : <SessionRecord />}

    </section>
  );
};

export default RecordList;
