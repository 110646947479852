import React from "react";
import { Search  } from "@mui/icons-material";
import { SearchData } from "@/types/types";
import { useSearchContext } from "@/contexts/SearchContext";

interface searchbarProps {
  label: string;
}

const Searchbar: React.FC<searchbarProps> = (props) => {
  const {  setSearchData } = useSearchContext();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData((prevData: SearchData) => ({
      ...prevData,
      plaintext: e.target.value,
    }));
  };

  return (
    <form
      role="search"
      aria-label={props.label}
      className="flex justify-center"
    >
      <div className="relative w-full">
        <input
          type="text"
          id="search-workouts"
          placeholder={props.label}
          aria-label={props.label}
          onChange={handleSearchChange}
          className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <button
            type="button"
            aria-label="search"
            className="text-gray-500 hover:text-gray-700"
          >
            <Search className="h-5 w-5" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Searchbar;
