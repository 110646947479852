import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { BACKEND_REST_URL } from '@/main';


interface Option {
    id: number;
    name: string;
    icon?: React.ReactNode;
}

interface OptionsContextType {
    branchOptions: Option[];
    environmentOptions: Option[];
    skillLevelOptions: Option[];
    categoryOptions: Option[];
    focusGroupOptions: Option[];
    disabilityOptions: Option[];
    equipmentOptions: Option[];
    fetchOptions: (optionType: string) => Promise<void>;
}

const OptionsContext = createContext<OptionsContextType | undefined>(undefined);

export const OptionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [branchOptions, setBranchOptions] = useState<Option[]>([]);
    const [environmentOptions, setEnvironmentOptions] = useState<Option[]>([]);
    const [skillLevelOptions, setSkillLevelOptions] = useState<Option[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
    const [focusGroupOptions, setFocusGroupOptions] = useState<Option[]>([]);
    const [disabilityOptions, setDisabilityOptions] = useState<Option[]>([]);
    const [equipmentOptions, setEquipmentOptions] = useState<Option[]>([]);
    const fetchOptions = async (optionType: string) => {
        try {
            const response = await fetch(`${BACKEND_REST_URL}/api/${optionType}/`, {
                headers: {
                    'accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            switch (optionType) {
                case 'branches':
                    setBranchOptions(data);
                    break;
                case 'environments':
                    setEnvironmentOptions(data);
                    break;
                case 'skill_levels':
                    setSkillLevelOptions(data);
                    break;
                case 'categories':
                    setCategoryOptions(data);
                    break;
                case 'focus_groups':
                    setFocusGroupOptions(data);
                    break;
                case 'disabilities':
                    setDisabilityOptions(data);
                    break;
                case 'equipment':
                    setEquipmentOptions(data);
                    break;
                default:
                    console.error(`Unknown option type: ${optionType}`);
            }
        } catch (error) {
            console.error(`Error fetching ${optionType}:`, error);
        }
    };
    useEffect(() => {
        // const cachedOptions = localStorage.getItem('cachedOptions');
        // if (cachedOptions) {
        //     const parsedOptions = JSON.parse(cachedOptions) as Record<string, Option[]>;
        //     if (Object.values(parsedOptions).some(arr => arr.length === 0)) {
        //         // If any of the cached arrays are empty, fetch all options again
        //         fetchAllOptions();
        //     } else {
        //         setBranchOptions(parsedOptions.branches);
        //         setEnvironmentOptions(parsedOptions.environments);
        //         setSkillLevelOptions(parsedOptions.skillLevels);
        //         setCategoryOptions(parsedOptions.categories);
        //         setFocusGroupOptions(parsedOptions.focusGroups);
        //         setDisabilityOptions(parsedOptions.disabilities);
        //         setEquipmentOptions(parsedOptions.equipment);
        //     }
        // } else {
            fetchAllOptions();
        // }
    }, []);

    const fetchAllOptions = async () => {
        await Promise.all([
            fetchOptions('branches'),
            fetchOptions('environments'),
            fetchOptions('skill_levels'),
            fetchOptions('categories'),
            fetchOptions('focus_groups'),
            fetchOptions('disabilities'),
            fetchOptions('equipment')
        ]);

        // const optionsToCache = {
        //     branches: branchOptions,
        //     environments: environmentOptions,
        //     skillLevels: skillLevelOptions,
        //     categories: categoryOptions,
        //     focusGroups: focusGroupOptions,
        //     disabilities: disabilityOptions,
        //     equipment: equipmentOptions
        // };
        // localStorage.setItem('cachedOptions', JSON.stringify(optionsToCache));
    };

    return (
        <OptionsContext.Provider value={{
            branchOptions,
            environmentOptions,
            skillLevelOptions,
            categoryOptions,
            focusGroupOptions,
            disabilityOptions,
            equipmentOptions,
            fetchOptions
        }}>
            {children}
        </OptionsContext.Provider>
    );
};

export const useOptions = () => {
    const context = useContext(OptionsContext);
    if (context === undefined) {
        throw new Error('useOptions must be used within an OptionsProvider');
    }
    return context;
};
