import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { BACKEND_REST_URL } from '@/main';
import i18next from 'i18next';

import { Language } from '@/types/types';

interface LanguageContextType {
    languages: Language[];
    currentLanguage: Language | null;
    setCurrentLanguage: (language: Language) => void;
    loading: boolean;
    error: string | null;
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    filteredLanguages: Language[];
    handleLanguageSelect: (languageCode: string) => void;
    activityLanguage: Language | null;
    setActivityLanguage: (language: Language) => void;
    sessionLanguage: Language | null;
    setSessionLanguage: (language: Language) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [languages, setLanguages] = useState<Language[]>([]);
    const [currentLanguage, setCurrentLanguage] = useState<Language | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [activityLanguage, setActivityLanguage] = useState<Language | null>(null);
    const [sessionLanguage, setSessionLanguage] = useState<Language | null>(null);

    const fetchLanguages = async () => {
        setLoading(true);
        try {
            const api_response = await fetch(`${BACKEND_REST_URL}/api/languages/`);
            const api_languages = await api_response.json();
            const languageCodes = api_languages.map((lang: Language) => ({ code: lang.name, id: lang.id }));
            const locales = i18next.services.resourceStore.data;

            const languages = languageCodes.map((code: { code: string, id: number }) => ({
                code: code.code,
                id: code.id,
                name: new Intl.DisplayNames([i18next.language], { type: 'language' }).of(code.code) || code.code,
                isLocale: locales.hasOwnProperty(code.code)
            }));

            // Sort languages: locales first, then alphabetically
            languages.sort((a: Language, b: Language) => {
                if (a.isLocale && !b.isLocale) return -1;
                if (!a.isLocale && b.isLocale) return 1;
                return a.name.localeCompare(b.name);
            });

            setLanguages(languages);

            // Set default language if not already set
            if (!currentLanguage && languages.length > 0) {
                let defaultLang;
                defaultLang = languages.find((lang: Language) => lang.code === i18next.resolvedLanguage);

                setCurrentLanguage(defaultLang);
            }
        } catch (error) {
            setError(error instanceof Error ? error.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        setActivityLanguage(currentLanguage);
        setSessionLanguage(currentLanguage);
    }, [currentLanguage]);

    const filteredLanguages = languages.filter(lang =>
        lang.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lang.code.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleLanguageSelect = (languageCode: string) => {
        const selectedLanguage = languages.find(lang => lang.code === languageCode);
        if (selectedLanguage) {
            setCurrentLanguage(selectedLanguage);
        }
    };

    useEffect(() => {
        fetchLanguages();
    }, []);

    // Add a new effect to update language names when UI language changes
    useEffect(() => {
        if (languages.length > 0) {
            const updatedLanguages = languages.map(lang => ({
                ...lang,
                name: new Intl.DisplayNames([i18next.language], { type: 'language' }).of(lang.code) || lang.code
            }));
            setLanguages(updatedLanguages);
        }
    }, [i18next.language]);

    return (
        <LanguageContext.Provider
            value={{
                languages,
                currentLanguage,
                setCurrentLanguage,
                loading,
                error,
                searchQuery,
                setSearchQuery,
                filteredLanguages,
                handleLanguageSelect,
                activityLanguage,
                setActivityLanguage,
                sessionLanguage,
                setSessionLanguage
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (context === undefined) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};
