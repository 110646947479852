import React, { useEffect, useRef, useCallback, useState } from "react";
import { useSearchContext } from "@/contexts/SearchContext";
import { useSessions } from "@/contexts/SessionContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Record from "@/components/simple/Record";
import { SessionRecordData } from "@/types/types";

export const SessionRecord: React.FC = () => {
  const { searchData } = useSearchContext();
  const {
    setSession,
    sessions,
    loading,
    total,
    clearSessions,
    fetchSessions,
  } = useSessions();
  const [skip, setSkip] = useState(0);
  const LIMIT = 50;

  const observerRef = useRef<IntersectionObserver | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const lastSessionRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading) return;

      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && sessions.length < total) {
          setSkip(prev => prev + LIMIT);
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [loading, sessions.length, total],
  );

  useEffect(() => {
    setSkip(0);
    clearSessions();
  }, [searchData, clearSessions]);

  useEffect(() => {
    fetchSessions(skip, LIMIT, searchData);
  }, [fetchSessions, skip, LIMIT, searchData]);

  const handleSessionClick = (session: SessionRecordData) => {
    setSession(session);
    navigate(`/session/${session.id}/${session.original_language_id}`);
    console.log(session);
  };

  return (
    <>
      {sessions.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {sessions.map((session, index) => (
            <div
              key={session.id}
              ref={index === sessions.length - 1 ? lastSessionRef : null}
            >
              <Record
                id={session.id}
                name={session.title || ''}
                description={session.description || ''}
                videoUrl={''}
                onClick={() => handleSessionClick(session)}
              />
            </div>
          ))}
        </div>
      )}

      {!loading && sessions.length === 0 && (
        <p className="text-xl text-center my-16">
          {t('common.noRecordsFound')}
        </p>
      )}


      {loading && (
        <div className="flex justify-center my-16">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      )}
    </>
  );
};