import { useTranslation } from "react-i18next";
import { TagGridProps} from "@/types/types";
import { TagSection, EditableTagSection } from "@/components/composed/Activity/TagSection";




export const TagGrid: React.FC<TagGridProps> = ({
  activity,
  isEditable,
  onChange,
  isChecked,
  errors,
  addableSections = [],
  visibleSections = ["branches", "categories", "skill_levels", "focus_groups", "environments", "disabilities", "equipment"]
}) => {
  const { t } = useTranslation();

  const sections = [
    { key: "skill_levels", title: t('activityForm.skillLevel'), items: activity.skill_levels, icon: '🔰', color: 'bg-green-200' },
    { key: "focus_groups", title: t('activityForm.focusGroup'), items: activity.focus_groups, icon: '👥', color: 'bg-blue-200' },
    { key: "categories", title: t('activityForm.category'), items: activity.categories, icon: '📋', color: 'bg-purple-200' },
    { key: "branches", title: t('activityForm.branch'), items: activity.branches, icon: '🌐', color: 'bg-red-200' },
    { key: "environments", title: t('activityForm.environment'), items: activity.environments, icon: '🏞️', color: 'bg-yellow-200' },
    { key: "disabilities", title: t('activityForm.disabilities'), items: activity.disabilities, icon: '🦮', color: 'bg-indigo-200' },
    { key: "equipment", title: t('activityForm.equipment'), items: activity.equipment, icon: '🏷️', color: 'bg-pink-200' },
  ];

  const filteredSections = sections.filter(section => visibleSections.includes(section.key));

  if (!addableSections.length) {
    filteredSections.map((section) => {
      addableSections.push(section.key)
    })
  }

  return (
    <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      {filteredSections.map((section) => (
        isEditable ? (
          <EditableTagSection
            title={section.title}
            key={section.key}
            sectionKey={section.key}
            icon={section.icon}
            items={section.items}
            color={section.color}
            onChange={onChange ? (value) => onChange(section.key, value) : undefined}
            isChecked={isChecked ? (value) => isChecked(section.key, value) : undefined}
            addableSections={addableSections}
            errors={errors}
          />
        ) : (
          <TagSection
            title={section.title}
            key={section.key}
            sectionKey={section.key}
            icon={section.icon}
            items={section.items}
            color={section.color}
          />
        )
      ))}
    </div>
  );
};