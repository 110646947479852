import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useAuth } from "@/contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { EnvelopeIcon, LockClosedIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

type Inputs = {
  email: string;
  password: string;
  confirmPassword: string;
  username: string;
};

const SignUpForm: React.FC = () => {
  const { signUp } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.password !== data.confirmPassword) {
      // Handle password mismatch error
      return;
    }
    await signUp(data.username, data.email, data.password);
  };

  return (
    <div className="bg-gray-100 flex justify-center px-4">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-900">
          {t("common.signUp")}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Username Field */}
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700 ">
              {t("auth.username")}
            </label>
            <div className="mt-1 relative rounded-md shadow-md">
              <input
                id="username"
                type="text"
                placeholder={t("auth.username")}
                {...register("username", { required: t("common.usernameRequired") })}
                className="input w-full pl-10 border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <UserIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            <ErrorMessage errors={errors} name="username" as="p" className="text-red-500 text-sm mt-1" />
          </div>

          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t("auth.email")}
            </label>
            <div className="mt-1 relative rounded-md shadow-md">
              <input
                id="email"
                type="email"
                placeholder={t("auth.email")}
                {...register("email", { required: t("common.emailRequired") })}
                className="input w-full pl-10 border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <EnvelopeIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            <ErrorMessage errors={errors} name="email" as="p" className="text-red-500 text-sm mt-1" />
          </div>

          {/* Password Field */}
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              {t("auth.password")}
            </label>
            <div className="mt-1 relative rounded-md shadow-md">
              <input
                id="password"
                type="password"
                placeholder={t("auth.password")}
                {...register("password", { required: t("common.passwordRequired") })}
                className="input w-full pl-10 border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            <ErrorMessage errors={errors} name="password" as="p" className="text-red-500 text-sm mt-1" />
          </div>

          {/* Confirm Password Field */}
          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
              {t("auth.confirmPassword")}
            </label>
            <div className="mt-1 relative rounded-md shadow-md">
              <input
                id="confirmPassword"
                type="password"
                placeholder={t("auth.confirmPassword")}
                {...register("confirmPassword", { required: t("common.confirmPasswordRequired") })}
                className="input w-full pl-10 border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            <ErrorMessage errors={errors} name="confirmPassword" as="p" className="text-red-500 text-sm mt-1" />
          </div>

          {/* Sign Up Button */}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {t("common.signUp")}
          </button>

          {/* Additional Links */}
          <div className="flex justify-between text-sm text-gray-600 mt-4">
            <Link to="/signin" className="hover:underline">
              {t("auth.alreadyHaveAccount")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;