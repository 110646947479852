import React from "react";
import { useOptions } from "@/contexts/OptionsContext";
import { useSearchContext } from "@/contexts/SearchContext";
import Searchbar from "@/components/simple/Searchbar";
import { Fieldset } from "@headlessui/react";
import {  SearchData } from "@/types/types";
import { useTranslation } from "react-i18next";
import { Tab, TabGroup, TabList } from "@headlessui/react";
import { MultiSelect } from "@/components/simple/MultiSelect";

const colorMapping = {
  branches: "bg-red-200",
  categories: "bg-purple-200",
  skill_levels: "bg-green-200",
  focus_groups: "bg-blue-200",
  environments: "bg-yellow-200",
  disabilities: "bg-indigo-200",
  equipment: "bg-pink-200",
};

const SearchContainer: React.FC = () => {
  const {
    branchOptions,
    environmentOptions,
    skillLevelOptions,
    categoryOptions,
    focusGroupOptions,
    disabilityOptions,
  } = useOptions();
  const { searchData, setSearchData } = useSearchContext();
  const { t } = useTranslation();

  const handleComboboxChange = (optionType: string, value: string[]) => {
    setSearchData((prevData: SearchData) => ({
      ...prevData,
      [optionType]: value,
    }));
  };

  const handleRemoveFromSelection = (optionType: string, value: string) => {
    const currentValues = searchData[optionType as keyof SearchData];
    if (Array.isArray(currentValues)) {
      const removed = currentValues.filter((item: string) => item !== value);
      setSearchData((prevData: SearchData) => ({
        ...prevData,
        [optionType]: removed,
      }));
    }
  };

  return (
    <div className=" items-center bg-gray-200/50 p-2 border-2 border-gray-300 rounded-lg">
      <div className="col-span-full">
        <Searchbar label={t("common.search")} />
      </div>
      <Fieldset className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 p-4">
        <MultiSelect
          label={t("categories.branch")}
          options={branchOptions}
          selectedValues={searchData.branch || []}
          onChange={(value) => handleComboboxChange("branch", value)}
          removeFromSelection={(value) =>
            handleRemoveFromSelection("branch", value)
          }
          color={colorMapping.branches}
        />

        <MultiSelect
          label={t("categories.environment")}
          options={environmentOptions}
          selectedValues={searchData.environment || []}
          onChange={(value) => handleComboboxChange("environment", value)}
          removeFromSelection={(value) =>
            handleRemoveFromSelection("environment", value)
          }
          color={colorMapping.environments}
        />

        <MultiSelect
          label={t("categories.skillLevel")}
          options={skillLevelOptions}
          selectedValues={searchData.skillevel || []}
          onChange={(value) => handleComboboxChange("skillevel", value)}
          removeFromSelection={(value) =>
            handleRemoveFromSelection("skillevel", value)
          }
          color={colorMapping.skill_levels}
        />

        <MultiSelect
          label={t("categories.category")}
          options={categoryOptions}
          selectedValues={searchData.categories || []}
          onChange={(value) => handleComboboxChange("categories", value)}
          removeFromSelection={(value) =>
            handleRemoveFromSelection("categories", value)
          }
          color={colorMapping.categories}
        />

        <MultiSelect
          label={t("categories.focusGroup")}
          options={focusGroupOptions}
          selectedValues={searchData.focusGroup || []}
          onChange={(value) => handleComboboxChange("focusGroup", value)}
          removeFromSelection={(value) =>
            handleRemoveFromSelection("focusGroup", value)
          }
          color={colorMapping.focus_groups}
        />

        <MultiSelect
          label={t("categories.disability")}
          options={disabilityOptions}
          selectedValues={searchData.disabilities || []}
          onChange={(value) => handleComboboxChange("disabilities", value)}
          removeFromSelection={(value) =>
            handleRemoveFromSelection("disabilities", value)
          }
          color={colorMapping.disabilities}
        />
      </Fieldset>
      <div className="flex justify-center">
        <div className="border-b border-gray-300 mb-4 w-4/12"></div>
      </div>
      <TabGroup className="flex justify-center">
        <TabList>
          <Tab
            className={`rounded-full py-2 px-6 text-lg font-semibold text-black focus:outline-none 
                                  data-[hover]:bg-black/5 
                                  data-[focus]:outline-1 data-[focus]:outline-black
                                  ${searchData.show_activities ? "bg-black/10" : "" }`}
            onClick={() => {
              setSearchData((prevData: SearchData) => ({
                ...prevData,
                show_activities: true,
              }));
            }}
          >
            {t("common.activities")}
          </Tab>
          <Tab
            className={`rounded-full py-2 px-6 text-lg font-semibold text-black 
                focus:outline-none  data-[hover]:bg-black/5 
                 data-[focus]:outline-1 data-[focus]:outline-black
                 ${!searchData.show_activities ? "bg-black/10" : "" }`}
            onClick={() => {
              setSearchData((prevData: SearchData) => ({
                ...prevData,
                show_activities: false,
              }));
            }}
          >
            {t("common.sessions")}
          </Tab>
        </TabList>
      </TabGroup>
    </div>
  );
};


export default SearchContainer;
