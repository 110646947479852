import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Container, Box, useMediaQuery, useTheme } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButtonWithText } from "@/components/simple/IconButtonWithText";
import { useAuth } from "@/contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import FloatingActionMenu from "@/components/composed/FloatingActionMenu";
import LanguageIcon from '@mui/icons-material/Language';
import LanguageModal from "@/components/simple/LanguageModal";

import Home from "@/pages/Home";
import { SignInPage } from "@/pages/signin";
import ActivityView from "@/pages/ActivityView";
import SessionView from "@/pages/SessionView";
import { ActivityCreate } from "@/components/composed/Activity/ActivityCreate";
import { SessionCreate } from "@/components/composed/Session/SessionCreate";
import MyPage from "@/pages/MyPage";
import { SignUpPage } from "./pages/SignUp";

const App: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoggedIn, userData } = useAuth();
  const { t, i18n } = useTranslation();
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  document.title = t("common.activityPlanner");
  const handleLanguageChange = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
  };

  const getLanguageName = (languageCode: string) => {
    return t(`language:${languageCode}`, { lng: languageCode });
  };

  const renderNavigation = () => (
    <Toolbar disableGutters className="flex justify-between items-center">
      <Typography variant="h6" component={Link} to="/" className="flex-grow no-underline text-inherit">
        {t('common.activityPlanner')}
      </Typography>
      <div className="flex items-center">
        <IconButtonWithText
          icon={<HomeIcon />}
          text={isMobile ? '' : t('common.home')}
          to="/"
        />
        <IconButtonWithText
          icon={<AccountCircleIcon />}
          text={isMobile ? '' : (isLoggedIn ? userData.user_name : t('common.signIn'))}
          to={isLoggedIn ? "/profile" : "/signin"}
        />
        <IconButtonWithText
          icon={<LanguageIcon />}
          text={isMobile ? '' : getLanguageName(i18n.language)}
          onClick={() => setIsLanguageModalOpen(true)}
        />
      </div>
    </Toolbar>
  );

  return (
    <Box className="flex flex-col min-h-screen">
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff",
          color: "#343a40",
          top: isMobile ? 'auto' : 0,
          bottom: isMobile ? 0 : 'auto',
        }}
      >
        <Container>{renderNavigation()}</Container>
      </AppBar>

      <LanguageModal
        isOpen={isLanguageModalOpen}
        onClose={() => setIsLanguageModalOpen(false)}
        onLanguageChange={handleLanguageChange}
      />


      <Box
        component="main"
        className="flex-grow"
        sx={{
          pt: isMobile ? 0 : 8,
          pb: isMobile ? 8 : 0,
          mt: 2
        }}
      >
        <Routes>
          <Route path="/createActivity" element={<ActivityCreate />} />
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/createSession" element={<SessionCreate />} />
          <Route path="/activity/:id/:language_id" element={<ActivityView />} />
          <Route path="/session/:id/:language_id" element={<SessionView />} />
          <Route path="/profile" element={<MyPage />} />  {/* TODO(Franz,Trygve) */}
        </Routes>
      </Box>

      <div className="mx-auto">
        {isLoggedIn && <FloatingActionMenu />}

      </div>
    </Box>
  );
};

export default App;