import React from 'react';
import { Delete, Edit, Save } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCreateActivity } from '@/contexts/CreateActivityContext';
import { useSnackbar } from '@/contexts/SnackbarProvider';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '@/contexts/LanguageContext';
import { useRecords } from '@/contexts/RecordsContext';
import { RecordData } from '@/types/types';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useCreateSession } from '@/contexts/CreateSessionContext';

const SaveFabComponent: React.FC = () => {
    const navigate = useNavigate();
    const { validateActivity, handlePost, activityPost, images } = useCreateActivity();
    const { activityLanguage } = useLanguage();
    const { showSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleSave = async () => {
        try {
            // Call your validation function here
            const isValid = validateActivity();
            if (!isValid) {
                showSnackbar(t('createActivity.common.error'), 'error');
                return;
            }

            if (isValid) {
                await handlePost(activityPost, images, activityLanguage!.id);
                navigate('/');
            }
        } catch (error) {
            console.error('Error validating activity:', error);
            // Handle validation error (maybe show a toast notification)
        }
    };

    return (
        <button
            onClick={handleSave}
            className="flex items-center justify-center w-16 h-16 bg-blue-500 hover:bg-blue-600 text-white rounded-full shadow-lg transition-transform"
        >
            <Save fontSize="large" />
        </button>
    );
};

export const SaveSessionFabComponent: React.FC = () => {
    const navigate = useNavigate();
    const { session, validateSession, handlePost } = useCreateSession();
    const { sessionLanguage } = useLanguage();
    const { showSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleSave = async () => {
        try {
            const isValid = validateSession();
            if (!isValid) {
                showSnackbar(t('createActivity.common.error'), 'error');
                return;
            }

            console.log(session);
            await handlePost(session, sessionLanguage!.id);
            showSnackbar(t('common.saved'), 'success');
            navigate('/');
        } catch (error) {
            console.error('Error saving session:', error);
            showSnackbar(t('createActivity.common.error'), 'error');
        }
    };

    return (
        <button
            onClick={handleSave}
            className="flex items-center justify-center w-16 h-16 bg-blue-500 hover:bg-blue-600 text-white rounded-full shadow-lg transition-transform"
        >
            <Save fontSize="large" />
        </button>
    );
};

export const DeleteFabComponent: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { handleDelete } = useRecords();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onConfirmDelete = () => {
        handleDelete();
        setIsOpen(false);
        navigate('/');
    }

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className="flex items-center justify-center w-16 h-16 bg-red-500 hover:bg-red-600 text-white rounded-full shadow-lg transition-transform"
            >
                <Delete fontSize="large" />
            </button>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <DialogPanel className="mx-auto max-w-sm rounded bg-white p-6">
                        <DialogTitle className="text-lg font-medium mb-4">
                            {t('common.confirmDelete')}
                        </DialogTitle>
                        <p className="mb-4">
                            {t('common.deleteWarning')}
                        </p>

                        <div className="flex justify-end gap-3">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="px-4 py-2 rounded bg-gray-200 hover:bg-gray-300"
                            >
                                {t('common.cancel')}
                            </button>
                            <button
                                onClick={onConfirmDelete}
                                className="px-4 py-2 rounded bg-red-500 hover:bg-red-600 text-white"
                            >
                                {t('common.delete')}
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </>
    );
};
export const SaveChangesFabComponent: React.FC = () => {
    const { validateActivity, handleUpdate } = useCreateActivity();
    const navigate = useNavigate();
    const { record, setIsEditing, setRecord } = useRecords();
    const { activityLanguage } = useLanguage();
    const { showSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const handleSave = async () => {
        try {
            const isValid = validateActivity();
            if (!isValid) {
                showSnackbar(t('createActivity.common.error'), 'error');
                return;
            }
            const response = await handleUpdate(record as RecordData);
            setIsEditing(false);
            setRecord(response);
            showSnackbar(t('common.saved'), 'success');
            navigate(`/activity/${response.id}/${activityLanguage?.id}`);
        } catch (error) {
            console.error('Error validating activity:', error);
        }
    }

    return (
        <button
            onClick={handleSave}
            className="flex flex-col items-center justify-center w-16 h-16 bg-blue-500 hover:bg-blue-600 text-white rounded-full shadow-lg transition-transform gap-1 p-2 text-xs"
        >
            <Save fontSize="small" />
            <span>{t('common.saveChanges')}</span>
        </button>
    );
};
export const EditFabComponent: React.FC = () => {
    const { setIsEditing } = useRecords();
    const { t } = useTranslation();
    return (
        <button
            onClick={() => setIsEditing(true)}
            className="flex flex-col items-center justify-center w-16 h-16 bg-blue-500 hover:bg-blue-600 text-white rounded-full shadow-lg transition-transform gap-1 p-2 text-xs"
        >
            <Edit fontSize="small" />
            <span>{t('common.editActivity')}</span>
        </button>
    );
};
export default SaveFabComponent;


